<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col md="8">
          <b-card>
            <b-tabs>
              <b-tab title="Detail">
                <b-row>
                  <b-col>
                    <b-form-group label="Kode" label-for="code">
                      <b-form-input
                        id="code"
                        placeholder="Kode"
                        v-model="form.code"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Kategori" label-for="category">
                      <b-form-select
                        id="category"
                        v-model="form.selectedCategory"
                        :options="form.optionsCategory"
                        required
                      />
                      <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Merk" label-for="brand">
                      <b-form-input
                        id="brand"
                        placeholder="Merk"
                        v-model="form.brand"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Nama" label-for="name">
                      <b-form-input
                        id="name"
                        placeholder="Nama"
                        v-model="form.name"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Warna" label-for="color">
                      <b-form-input
                        id="color"
                        placeholder="Warna"
                        v-model="form.color"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Tahun" label-for="year">
                      <b-form-input
                        id="year"
                        placeholder="Tahun"
                        v-model="form.year"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Transmisi" label-for="transmission">
                      <b-form-input
                        id="transmission"
                        placeholder="Transmisi"
                        v-model="form.transmission"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Tipe Mesin" label-for="machineType">
                      <b-form-input
                        id="machineType"
                        placeholder="Tipe Mesin"
                        v-model="form.machineType"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Kapasitas Mesin"
                      label-for="engineCapacity"
                    >
                      <b-form-input
                        id="engineCapacity"
                        placeholder="Kapasitas Mesin"
                        v-model="form.engineCapacity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Jumlah Penumpang"
                      label-for="Passengers"
                    >
                      <cleave
                        id="passengers"
                        placeholder="Jumlah Penumpang"
                        v-model="form.passengers"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Jumlah Ban" label-for="tires">
                      <b-form-input
                        id="tires"
                        placeholder="Jumlah Ban"
                        v-model="form.tires"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Golongan" label-for="Gologan">
                      <b-form-input
                        id="class"
                        placeholder="Golongan"
                        v-model="form.class"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor Polisi" label-for="policeNo">
                      <b-form-input
                        id="policeNo"
                        placeholder="Nomor Polisi"
                        v-model="form.policeNo"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal berakhir STNK"
                      label-for="registration-date"
                    >
                      <flat-pickr
                        v-model="form.registrationDate"
                        class="form-control"
                        required
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor STNK" label-for="registrationNo">
                      <b-form-input
                        id="registrationNo"
                        placeholder="Nomor STNK"
                        v-model="form.registrationNo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Nomor Rangka" label-for="chassisNo">
                      <b-form-input
                        id="chassisNo"
                        placeholder="Nomor Rangka"
                        v-model="form.chassisNo"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor Mesin" label-for="machineNo">
                      <b-form-input
                        id="machineNo"
                        placeholder="Nomor Mesin"
                        v-model="form.machineNo"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Pembelian"
                      label-for="purchase-date"
                    >
                      <flat-pickr
                        v-model="form.purchaseDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Harga Pembelian" label-for="price">
                      <cleave
                        id="price"
                        placeholder="Harga"
                        v-model="form.price"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Penjualan"
                      label-for="purchase-date"
                    >
                      <flat-pickr
                        v-model="form.selling_date"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Harga Penjualan" label-for="price">
                      <cleave
                        id="price"
                        placeholder="Harga"
                        v-model="form.sellingPrice"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Kilometer" label-for="mileage">
                      <cleave
                        id="mileage"
                        placeholder="Kilometer"
                        v-model="form.mileage"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Update"
                      label-for="update-date"
                    >
                      <flat-pickr
                        v-model="form.mileageDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Deskripsi" label-for="Deskripsi">
                  <quill-editor v-model="form.description" />
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mt-2"
                  @click="addUnit()"
                >
                  Kirim
                </b-button>
              </b-tab>
              <b-tab title="Foto"> </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Foto"
              label-for="Image"
              invalid-feedback="Foto tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="Foto"
                rules="required"
              >
                <image-uploader
                  id="file"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    flatPickr,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        selectedCategory: null,
        optionsCategory: [],
        milage: null,
        description: "",
        purchaseDate: null,
        registrationDate: null,
        kirDate: null,
        price: null,
        transmission: null,
        passengers: null,
        engineCapacity: null,
        brand: null,
        color: null,
        year: null,
        registrationNo: null,
        policeNo: null,
        chassisNo: null,
        machineNo: null,
        machineType: null,
        class: null,
        tires: null,
        sellingDate: null,
        sellingPrice: null,
        mileageDate: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      errors: "",
      errMessage: "",
      hasImage: "",
      image: "",
      photo: "",
      errMessageImage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getCategories();
  },
  updated() {
    console.log(document.getElementById("file").files[0]);
  },
  methods: {
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    getCategories() {
      this.$http.get("unit/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsCategory.push({
          value: null,
          text: "Please select 1 Category",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.form.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    addUnit() {
      var newMileage = this.form.mileage;

      if (newMileage != null) {
        newMileage = newMileage.replace(/\,/g, "");
      }

      var newPrice = this.form.price;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }

      var newSellingPrice = this.form.sellingPrice;

      if (newSellingPrice != null) {
        newSellingPrice = newSellingPrice.replace(/\,/g, "");
      }

      this.$http
        .post("unit/add", {
          code: this.form.code,
          name: this.form.name,
          category_id: this.form.selectedCategory,
          mileage: newMileage,
          description: this.form.description,
          image: this.image,
          purchase_date: this.form.purchaseDate,
          registration_date: this.form.registrationDate,
          kir_date: this.form.kirDate,
          transmission: this.form.transmission,
          price: newPrice,
          passengers: this.form.passengers,
          engine_capacity: this.form.engineCapacity,
          brand: this.form.brand,
          color: this.form.color,
          year: this.form.year,
          registration_no: this.form.registrationNo,
          police_no: this.form.policeNo,
          chassis_no: this.form.chassisNo,
          machine_no: this.form.machineNo,
          machine_type: this.form.machineType,
          class: this.form.class,
          tires: this.form.tires,
          selling_date: this.form.sellingDate,
          selling_price: newSellingPrice,
          mileage_date: this.form.mileageDate,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Unit baru berhasil ditambahkan",
              variant: "success",
            },
          });
          location.href = "/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>